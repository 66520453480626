.overflow_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.p25 {
    padding: 0.25em !important;
}

.no_select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.react-datepicker__close-icon {
    -webkit-transition: opacity 0.1s ease;
    padding-right: 0.75em;
    opacity: 0.8;
    transition: opacity 0.1s ease;
}

.react-datepicker__close-icon:hover {
    opacity: 1;
}

.react-datepicker__close-icon::after {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    content: "\f00d";
    font-family: 'Dropdown';
}

.dont_padd {
    padding: 0px !important;
}

/* center your shit */
.flexy {
    display: flex;
    align-items: center;
    justify-content: center;
}
