div.settings-container {
    height: 100vh;
    overflow-y: scroll;
    margin-bottom: 5em;
    padding-bottom: 5em;
}

.inline-span {
    display: block;
}

.active-header-font {
    font-size: 24px;
    font-weight: bold;
}

.inactive-header-font {
    font-size: 24px;
    font-weight: normal;
}
