#add-client-popup .ui.scrolling.dropdown .menu,
#add-client-popup .ui.dropdown .scrolling.menu {
  max-height: 40vh !important;
}

#add-client-popup .ui.dropdown .item {
  line-height: 1.5 !important;
}


#add-client-button{
  margin:0;
  padding: 0.2em;
  background: none;
  opacity:0.2;
  transition:opacity 500ms;
}


.ui.horizontal.segments:hover #add-client-button {
  opacity:1;
}