.ui.container.workflow-container {
    min-width: 100%;
    min-height: 200px;
    height: 100%;
    display: flex;
    direction: row;
    flex: 1 1 auto;
    overflow-x: scroll;
    margin-left: 0 !important;
    margin-right: 1em;
    padding: 0em 1em 0em 1em;
}

.ui.placeholder.segment {
    min-height: 12em;
}

.hubly_bars_menu {
    padding:0px !important;
    border-color: #bababc !important;
}

.hubly_bars_menu:before {
    background-color: #f4f4f4 !important;
}

.hubly_bars_menu > .ui.buttons {
    border: none;
}

.hubly_bars_menu > .ui.buttons > .ui.button {
    text-align: left;
}

/* For the A component to allow clickable text */
.clickable_text {
    color: #2185d0;
}

.non_clickable_text {
    color: #3c3c3c;
}

.clickable_text:hover {
    color: #186199;
    cursor: pointer;
}

.clickable:hover {
    cursor: pointer;
}

.editable:hover {
    cursor: text;
}

.underline_on_hover:hover {
    text-decoration: underline;
}

.grey_on_hover {
    transition: background-color 0.15s;
}

.grey_on_hover:hover {
    background-color: #f2f2f2 !important;
}

.dark_grey_bg_on_hover:hover {
    background-color: #e2e2e2 !important;
}

.dark_grey_on_hover {
    color: #dadada;
    transition: color 0.15s;
}

.dark_grey_on_hover:hover {
    color: #aaaaaa !important;
}

.reduce_opacity_on_hover {
    opacity: 1;
    transition: opacity 0.2s ease;
}
.reduce_opacity_on_hover:hover {
    opacity: 0.8;
}

.grey_on_hover_circle {
    padding: 4.5px 0px 4.5px 1px !important;
    transition: background-color 0.15s;
    width: 2em !important;
    height: 2em !important;
    border-radius: 50%;
}

.grey_on_hover_circle:hover {
    background-color: #f2f2f2 !important;
}

.ui.sidebar.hub-feed-sidebar {
    overflow: visible !important;
}

div.ui.disabled.input {
    opacity: 1;
}

.no_bold {
    font-weight: normal !important;
}

.grey_on_hover .show_on_hover {
    opacity: 0;
    transition: opacity 0.1s;
}

.grey_on_hover:hover .show_on_hover {
    opacity: 1;
}

.scrolling.dimmable > .ui.modals.dimmer {
    justify-content: center;
}

.client-card-workflow div.ui.dropdown > i.dropdown {
    margin-left: .5em;
}

.rounded {
    border-radius: 6px;
}

.noIconScrollBar::-webkit-scrollbar {
    display: none;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}