.card_left_component .ui.progress {
	margin-bottom: 0em;
}

.card_left_component .ui.progress > .bar {
	min-width: 2.5em;
}

.card_left_component .ui.progress > .bar > .progress {
	right: .25em;
}

.right_shift {
	margin-left:5%;
}

div.next-workflows-search-dropdown div.text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

ul.react-datepicker__time-list {
	height: calc(191px + (1.7rem / 2)) !important;
}

.snooze_option {
	border-radius: 5px;
}
.snooze_option:hover {
	background-color: #eee;
	cursor: pointer;
}

.react-datepicker__day--selected {
	background-color: #2185d0 !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
	height: auto !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
	background-color: #2185d0 !important;
}

/* Round the corners of Quill */
.quill{
	border-radius: 5px;
	box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}

.ql-editor.display_description {
	min-height: 0px;
}

.ql-toolbar.ql-snow{
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.ql-container.ql-snow{
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.invertedLoader:after{
	border-color: rgba(0,0,0,0.3) transparent transparent !important;
}

.invertedLoader:before{
	border-color: lightgrey !important;
}

div.client-card-tags div.item:hover {
	background-color: #f8f8f8;
}

div.client-card-tags div.list div.item div.description {
	overflow: hidden;
	padding: 0 !important;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 190px !important;
	min-height: 1.2em;
}

div.time-estimate:hover {
	border-radius: 0.25em;
	cursor: pointer;
}

i.time-estimate-icon {
	color: #e0e1e2;
}

i.time-estimate-icon:hover {
	color: #cacbcd;
}

#client_card{
	display: flex !important;
	flex-direction: column !important;
	max-height: calc(100vh - 2.25em);
	margin: 0px;
}

#client_card > .scrolling.content{
	max-height: none;
	flex-grow: 1;
}

#client_card .taskDescriptionDiv p {
	margin-bottom: 0;
}

#client_card .react-datepicker-wrapper {
	display: inline;
}

#client_card .editableField:hover {
	cursor: pointer;
}

.wealthbox_color {
	color: #1371d6;
}

.redtail_color {
	color: #b63135;
}

.react-datepicker-popper {
	z-index: 9999;
}