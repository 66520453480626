.accordian_title {
    padding: 1em !important;
    font-weight: bold;
    font-size: 11pt !important;
    user-select: none;
}

.accordian_content {
    padding: 0em 1em 1em 4em !important;
}

.grey_BG {
    background: #f2f2f2 !important;
}
