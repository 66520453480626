.ui.placeholder.segment {
    max-height: 12em;
    min-height: 12em;
    border-width: 0.8px;
}

.ui.segment.workflow {
    display: flex;
    flex-flow: row;
    float: left;
    height: 100%;
    margin-top: 0;
    margin-right: 1em;
    padding: 0;
}

.workflow_transition {
    transition: 0.25s;
}

.ui.container.workflow_stage > div {
    padding: 0.8em;
}

.ui.container.workflow_stage button {
    margin-top: 5px;
}

.ui.container.workflow_name {
    margin: 0 !important;
    border-bottom: 1px #e0e1e2 solid;
    background-color: #fff;
    transition: background-color 0.2s;
}

.ui.container.workflow_name:hover {
    background-color: #f4f4f4;
}

.ui.container.workflow_name > button{
    margin-top: 0;
}

.ui.container.workflow_name > h3 {
    margin-top: 0;
    font-size: 12pt;
}

.mini_card{
    transition: background-color 0.15s;
}

.mini_card:hover, .add_client_button:hover {
    background-color:#eaeaea !important;
    cursor:pointer;
    border-radius: 3px;
}

.ui.container.task_list > .list > .item {
    height: 45px;
}

.view_workflows .workflow_content:not(:hover)::-webkit-scrollbar {
    display: none;
    overflow: auto;
}

.rule_modal_dropdown_tag_label > i {
    margin-right: .25em !important;
}

@media only screen and (max-width: 767px) {
    .ui.workflow_stage.container {
        width: auto !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

}

/*Prevent the workflows settings next-workflow dropdown from getting to tall*/
.next-workflows-search-dropdown > .menu{
    max-height: 250px !important;
}

.hubly_bars_menu > div > button.ui.button.red_background { background: #faaeae !important; }
.hubly_bars_menu > div > button.ui.button.red_background:hover { background: #f88888 !important; }
