@media only screen and (max-width: 700px)  {
	#filter-workflow-button i {
		margin: 0;
	}
}

.client_dropdown {
	min-height: 0px !important;
	height: 30.5px !important;
	padding: 0.5em !important;
}

.client_dropdown > input.search {
	padding: 0.5em !important;
}

.dropdown_icon > div.default.text {
	padding-left: 1.6em !important;
}

.client_dropdown > i.dropdown.icon {
	padding: 0.5em !important;
}

/* center the semantic-ui loader icon vertically */
.client_dropdown.ui.loading.selection.dropdown > i.icon {
    padding-top: 0.9em !important;
}

.client_dropdown.ui.scrolling.dropdown .menu {
    max-height: calc(80vh - 250px) !important;
}
  

#recent-contact-header.disabled.item {
	opacity: 1 !important;
}

.ui.progress.sync-loader-progress {
	border-radius: 0;
}

#main_client_search_bar > .visible.menu.transition {
	max-height: 80vh !important;
}
